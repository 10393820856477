export const AVATARS: string[] = [
  "ant",
  "bear",
  "bee",
  "bird",
  "blackjaguar",
  "bull",
  "butterfly",
  "cat",
  "chicken",
  "cobra",
  "cow",
  "dog",
  "elephant",
  "falcon",
  "fish",
  "frog",
  "giraffe",
  "hedgehog",
  "hamster",
  "horse",
  "ladybug",
  "lamb",
  "lizard",
  "owl",
  "penguin",
  "pig",
  "shark",
  "spider",
  "starfish",
  "stork",
  "turtle",
  "unicorn",
  "whale",
  "wolf",
  "zebra",
];
